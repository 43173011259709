import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../components/atoms';
import { Layout } from '../../components/templates';
import { useAppDispatch } from '../../hooks';
import { registerQuery } from '../../store/api/auth.api';
interface IForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  referral_code: string;
  phone: string;
}
const RegisterPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [registered, setRegistered] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IForm>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    referral_code: '',
    phone: '',
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(registerQuery(form)).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') setRegistered(true);
      else setRegistered(false);
    });
  };
  React.useEffect(() => {
    const { email, password, firstName, lastName, referral_code, phone } = form;
    if (
      email !== '' &&
      password !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      referral_code !== '' &&
      phone !== ''
    )
      setIsValid(true);
    else setIsValid(false);
  }, [form]);
  return (
    <Layout>
      <div className="container mx-auto w-full z-10 py-40">
        <h1 className="text-4xl font-bold text-center  mb-5">Register</h1>
        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-5 md:gap-12">
          <div className="w-full">
            {!registered ? (
              <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-5">
                  <label className="text-sm">Firstname</label>
                  <input
                    type={'text'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm">Lastname</label>
                  <input
                    type={'text'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm">Phone</label>
                  <input
                    type={'tel'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm">Email</label>
                  <input
                    type={'text'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm">Password</label>
                  <input
                    type={'password'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, password: e.target.value })}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm">Referral Code</label>
                  <input
                    type={'text'}
                    className="w-full"
                    onChange={(e) => setForm({ ...form, referral_code: e.target.value })}
                  />
                </div>

                <div className="w-full flex flex-wrap justify-start">
                  <Link to="/recover-account" className="text-violet text-md">
                    Forgot password ?
                  </Link>
                </div>
                <div className="w-full flex flex-wrap items-center justify-end">
                  <Link to="/login" className="pr-4 text-white text-md">
                    SIGN IN
                  </Link>
                  <input
                    type="submit"
                    value="SIGN UP"
                    className={isValid ? 'bg-violet' : 'bg-gray-400'}
                    disabled={!isValid}
                  />
                </div>
              </form>
            ) : (
              <div className="w-1/2 mx-auto">
                <h2 className="text-h2 text-center font-semibold pb-5">Successful registration</h2>
                <p className="text-lg text-center px-32">
                  Your registration was successful, in the next 72 hours your registration request
                  will be answered and you will receive an email with the confirmation
                </p>
                <div className="flex flex-wrap justify-center my-10">
                  <Button text="Go to home" handleClick={() => navigate('/')} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RegisterPage;
