interface IProps {
  comissions: { [key: string]: number | string }[];
}
const Component = (props: IProps) => {
  const { comissions } = props;

  return (
    <div className="relative w-full overflow-x max-h-[500px] overflow-y-scroll rounded-md border border-darkgray rounded-b-md">
      <div className="w-full bg-darkgray rounded-t-md sticky top-0 left-0">
        <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg">
          Comissions by months
        </h2>
      </div>
      <div className="overflow-x">
        <table className="table-auto overflow-scroll w-full  border border-darkgray rounded-b-md">
          {' '}
          <thead>
            <th className="py-2 text-center">Month</th>
            <th className="py-2 text-center">Comissions</th>
          </thead>
          <tbody>
            <>
              {comissions.map((c) => (
                <tr key={c._id}>
                  <td className="py-2 text-center">{c.month}</td>
                  <td className="py-2 text-center">{`${(c.comission as number).toFixed(
                    3
                  )} USDT`}</td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Component;
