import React from 'react';
import { AdminDashboard } from '..';
import { CreateUser, Layout } from '../../components/templates';
import { useAppDispatch } from '../../hooks';
import { usersQuery } from '../../store/api/admin.api';

const Page = () => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(usersQuery('all'));
  }, []);

  return (
    <Layout>
      <AdminDashboard title="Create User">
        <CreateUser />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
