import { AdminDashboard } from '..';
import { Layout, UploadFairshare } from '../../components/templates';

const Page = () => {
  return (
    <Layout>
      <AdminDashboard title="Upload new fairshare">
        <UploadFairshare />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
