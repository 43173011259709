import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppShell from './AppShell';
import { store } from './store/store';

function App() {
  return (
    <Provider store={store}>
      <AppShell />
      <ToastContainer />
    </Provider>
  );
}

export default App;
