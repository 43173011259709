import React from 'react';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { editProfileQuery } from '../../store/api/auth.api';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState({
    email: user?.email as string,
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  });
  React.useEffect(() => {
    if (form.new_password !== '') {
      if (
        form.new_password === form.new_password_confirm &&
        form.old_password !== '' &&
        form.email !== ''
      )
        setIsValid(true);
      else setIsValid(false);
    } else {
      if (form.old_password !== '' && form.email !== '') {
        setIsValid(true);
      } else setIsValid(false);
    }
  }, [form]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(editProfileQuery(form)).then(
      (res) => res.meta.requestStatus === 'fulfilled' && navigate('/')
    );
  };
  return (
    <Layout>
      <div className="container mx-auto w-full z-10 py-40">
        <h1 className="text-4xl font-bold text-center">Edit Profile</h1>
        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-5 md:gap-12">
          <div className="w-full">
            <form className="w-full lg:w-1/2 mx-auto mt-5 " onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-5">
                <label className="text-sm">Email</label>
                <input
                  type={'text'}
                  defaultValue={form.email}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">Old Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, old_password: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">New Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, new_password: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">Confirm new Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, new_password_confirm: e.target.value })}
                />
              </div>
              <input type="submit" value="SIGN IN" disabled={!isValid} className="bg-violet" />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProfilePage;
