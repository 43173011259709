import { AdminDashboard } from '..';
import { Layout } from '../../components/templates';

const Page = () => {
  return (
    <Layout>
      <AdminDashboard title="Welcome back admin">
        <></>
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
