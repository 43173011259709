import { User } from '../../../models/User';

interface IProps {
  users: User[];
  fairshare_value: number;
}
const Component = (props: IProps) => {
  const { users, fairshare_value } = props;

  return (
    <div className="w-full overflow-x max-h-[500px] overflow-y-scroll rounded-md border border-darkgray rounded-b-md">
      <div className="w-full bg-darkgray rounded-t-md sticky top-0 left-0">
        <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg">
          Comissions by users
        </h2>
      </div>
      <div className="overflow-x">
        <table className="table-auto overflow-scroll w-full  border border-darkgray rounded-b-md">
          {' '}
          <thead>
            <th className="py-2 text-center">User name</th>
            <th className="py-2 text-center">Since Inception Performance</th>
            <th className="py-2 text-center">Consolidated funds</th>
            <th className="py-2 text-center">Actual month comission</th>
            <th className="py-2 text-center">Total comission</th>
          </thead>
          <tbody>
            <>
              {users.map((u) => (
                <tr key={u._id}>
                  <td className="py-2 text-center">
                    {u.firstName} {u.lastName}
                  </td>
                  <td className="py-2 text-center">{`${u.wallet?.since_inception?.toFixed(
                    2
                  )} %`}</td>
                  <td className="py-2 text-center">{`${(
                    (u.wallet ? u.wallet.consolidated_shares : 0) * fairshare_value
                  ).toFixed(3)} USDT`}</td>
                  <td className="py-2 text-center">{`${u.actualMonthComission?.toFixed(
                    3
                  )} USDT`}</td>
                  <td className="py-2 text-center">{`${u.totalComission?.toFixed(2)} USDT`}</td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Component;
