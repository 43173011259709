import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from '../../../hooks';
import { TransactionRequest } from '../../../models/TransactionRequest';
import {
  approveTransactionQuery,
  deniedTransactionQuery,
  transactionsQuery,
  transactionsRequestQueryAll,
  usersQuery,
} from '../../../store/api/admin.api';

interface IProps {
  transactions: TransactionRequest[];
}
const Component = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { transactions } = props;
  const approveTransaction = (id: string) => {
    dispatch(approveTransactionQuery(id)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(transactionsRequestQueryAll());
        dispatch(transactionsQuery());
        dispatch(usersQuery('all'));
      }
    });
  };
  const deniedTransaction = (id: string) => {
    dispatch(deniedTransactionQuery(id)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(transactionsRequestQueryAll());
        dispatch(transactionsQuery());
        dispatch(usersQuery('all'));
      }
    });
  };
  return (
    <table className="table-auto overflow-scroll w-full responsive text-center border border-gray-400">
      <thead>
        <th className="py-2">User</th>
        <th className="py-2">Type</th>
        <th className="py-2">Operation ID</th>
        <th className="py-2">Amount</th>
        <th className="py-2">Date</th>
        <th className="py-2">Address</th>
        <th className="py-2">Actions</th>
      </thead>
      <tbody>
        <>
          {transactions.map((t) => (
            <tr key={t._id}>
              <td className="py-2">
                {t.user.firstName} {t.user.lastName}
              </td>
              <td className="py-2">{t.type}</td>
              <td className="py-2">{t.operation_id}</td>
              <td className="py-2">{t.amount}</td>
              <td className="py-2">{t.date}</td>
              <td className="py-2">{t.address}</td>
              {t.status === 'PENDING' && (
                <td className="py-2">
                  <div className="flex flex-wrap items-center justify-center gap-x-2">
                    <div className="bg-white hover:bg-green-400 rounded-xl w-4 h-4">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-black"
                        onClick={() => approveTransaction(t._id)}
                      />
                    </div>
                    <div className="bg-white hover:bg-red-400 rounded-xl w-4 h-4">
                      <FontAwesomeIcon
                        icon={faClose}
                        style={{ color: '#000' }}
                        onClick={() => deniedTransaction(t._id)}
                      />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};
export default Component;
