import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from '../../../models/User';
import { useAppDispatch } from '../../../hooks';
import { approveUserQuery, deniedUserQuery, usersQuery } from '../../../store/api/admin.api';

interface IProps {
  users: User[];
}
const Component = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { users } = props;
  const approveUser = (id: string) => {
    dispatch(approveUserQuery(id)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') dispatch(usersQuery('all'));
    });
  };
  const deniedUser = (id: string) => {
    dispatch(deniedUserQuery(id)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') dispatch(usersQuery('all'));
    });
  };
  return (
    <table className="table-auto overflow-scroll w-full responsive text-center border border-gray-400">
      <thead>
        <th className="py-2">Role</th>
        <th className="py-2">Name</th>
        <th className="py-2">Email</th>
        <th className="py-2">Phone</th>
        <th className="py-2">Referral</th>
        <th className="py-2">Status</th>
        <th className="py-2">Consolidated Funds</th>
        <th className="py-2">Frozen Funds</th>
        <th className="py-2">Actions</th>
      </thead>
      <tbody>
        <>
          {users.map((u) => (
            <tr key={u._id}>
              <td className="py-2">{u.type}</td>
              <td className="py-2">
                {u.firstName} {u.lastName}
              </td>
              <td className="py-2">{u.email}</td>
              <td className="py-2">{u.phone}</td>
              <td className="py-2">{u.referral_code}</td>
              <td className="py-2">{u.status}</td>
              <td className="py-2">{u.wallet?.consolidated_shares.toFixed(2)}</td>
              <td className="py-2">{u.wallet?.frozen_shares.toFixed(2)}</td>
              {u.status === 'PENDING' && (
                <td className="py-2">
                  <div className="flex flex-wrap items-center justify-center gap-x-2">
                    <div className="bg-white hover:bg-green-400 rounded-xl w-4 h-4">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-black"
                        onClick={() => approveUser(u._id)}
                      />
                    </div>
                    <div className="bg-white hover:bg-red-400 rounded-xl w-4 h-4">
                      <FontAwesomeIcon
                        icon={faClose}
                        style={{ color: '#000' }}
                        onClick={() => deniedUser(u._id)}
                      />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};
export default Component;
