import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Transaction } from '../../../models/Transaction';
import { deleteTransaction, transactionsByIdQuery } from '../../../store/api/admin.api';
import { useAppDispatch } from '../../../hooks';
import { getTransactionsQuery } from '../../../store/api/user.api';

interface IProps {
  transactions: Transaction[];
}
const Component = (props: IProps) => {
  const { transactions } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEdit = (id: string) => {
    dispatch(transactionsByIdQuery(id)).then(
      (res) => res.meta.requestStatus === 'fulfilled' && navigate(`/admin/transactions/${id}/edit`)
    );
  };
  const handleDelete = (id: string) => {
    dispatch(deleteTransaction(id)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') dispatch(getTransactionsQuery());
    });
  };

  return (
    <table className="table-auto overflow-scroll w-full responsive text-center border border-gray-400">
      <thead>
        <th className="py-2">User</th>
        <th className="py-2">Type</th>
        <th className="py-2">Operation ID</th>
        <th className="py-2">Amount</th>
        <th className="py-2">Fairshare</th>
        <th className="py-2">Date</th>
        <th className="py-2">Address</th>
        <th className="py-2">Actions</th>
      </thead>
      <tbody>
        <>
          {transactions.map((t) => (
            <tr key={t._id}>
              <td className="py-2">
                {t.user.firstName} {t.user.lastName}
              </td>
              <td className="py-2">{t.type}</td>
              <td className="py-2">{t.operation_id}</td>
              <td className="py-2">{t.amount}</td>
              <td className="py-2">{t.fairshare}</td>
              <td className="py-2">{t.date}</td>
              <td className="py-2">{t.address}</td>
              <td className="py-2 cursor-pointer">
                <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(String(t._id))} />
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleDelete(String(t._id))}
                  className="ml-2"
                />
              </td>
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};
export default Component;
