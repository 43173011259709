import { AdminDashboard } from '..';
import { EditTransaction, Layout } from '../../components/templates';
import { useAppSelector } from '../../hooks';

const Page = () => {
  const { selectedTransaction } = useAppSelector((state) => state.admin);

  return (
    <Layout>
      <AdminDashboard title="Edit Transaction">
        {selectedTransaction ? <EditTransaction transaction={selectedTransaction} /> : <></>}
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
