import React from 'react';
import { AdminDashboard } from '..';
import { ReferralCodesTable } from '../../components/organisms/Tables';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { listRefferalCodeQuery } from '../../store/api/admin.api';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { referralCodes } = useAppSelector((state) => state.admin);
  React.useEffect(() => {
    dispatch(listRefferalCodeQuery());
  }, []);

  return (
    <Layout>
      <AdminDashboard
        title="Refferal Codes"
        button={{
          text: 'Create Refferal code',
          handleClick: () => navigate('/admin/refferal-codes/create'),
        }}>
        <ReferralCodesTable codes={referralCodes} />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
