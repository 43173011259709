import React from 'react';
import { AdminDashboard } from '..';
import { RequestsTable } from '../../components/organisms/Tables';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { transactionsRequestQueryAll } from '../../store/api/admin.api';

const Page = () => {
  const dispatch = useAppDispatch();
  const { transactions_requests } = useAppSelector((state) => state.admin);
  React.useEffect(() => {
    dispatch(transactionsRequestQueryAll());
  }, []);

  return (
    <Layout>
      <AdminDashboard title="List Transactions requests">
        <RequestsTable transactions={transactions_requests} />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
