import moment from 'moment';
import React from 'react';
import { Fairshare } from '../../../models/Fairshare';
import { Button } from '../../atoms';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { editFairshareQuery, fairsharesQuery } from '../../../store/api/admin.api';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  fairshares: Fairshare[];
}
const Component = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { isFetching } = useAppSelector((state) => state.admin);
  const { fairshares } = props;
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [fairshare, setFairshare] = React.useState<Fairshare>({
    _id: '',
    value: 0,
    comission: 0,
    date: moment(new Date()).format('YYYY-MM-DD'),
  });
  const handleClick = (fairshare: Fairshare) => {
    setFairshare(fairshare);
    setModalOpen(true);
  };
  const handleSubmit = () => {
    dispatch(
      editFairshareQuery({
        id: fairshare._id as string,
        value: fairshare.value,
        comission: fairshare.comission,
        date: fairshare.date,
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(fairsharesQuery());
        setModalOpen(false);
      }
    });
  };
  return (
    <>
      <table className="table-auto overflow-scroll w-full responsive text-center border border-gray-400">
        <thead>
          <th className="py-2">Date</th>
          <th className="py-2">Value</th>
          <th className="py-2">Comission</th>
          <th className="py-2">Actions</th>
        </thead>
        <tbody>
          <>
            {fairshares.map((f) => (
              <tr key={f._id}>
                <td className="py-2">{f.date}</td>
                <td className="py-2">{f.value}</td>
                <td className="py-2">{f.comission}</td>
                <td className="py-2 flex flex-wrap justify-center">
                  <div className="w-1/3 cursor-pointer">
                    <FontAwesomeIcon icon={faEdit} onClick={() => handleClick(f)} />
                  </div>
                </td>
              </tr>
            ))}
          </>
        </tbody>
      </table>
      {modalOpen && (
        <div className="w-full h-full absolute top-0 left-0">
          <div className="w-1/4 h-auto px-10 bg-black border border-lime mx-auto my-60 py-20 flex flex-wrap justify-center">
            <h2 className="w-full text-white text-h3 text-center mb-5">Edit Fairshare</h2>
            <label className="w-full mb-1 font-semibold">Date</label>
            <input
              type="date"
              className="w-full mb-4"
              placeholder="Fecha"
              step="any"
              defaultValue={fairshare.date}
              onChange={(e) =>
                setFairshare({
                  ...fairshare,
                  date: moment(e.target.value).format('YYYY-MM-DD'),
                })
              }
            />
            <label className="w-full mb-1 font-semibold">Value</label>
            <input
              type="number"
              className="w-full mb-4"
              onChange={(e) => setFairshare({ ...fairshare, value: +e.target.value })}
              defaultValue={fairshare.value}
            />
            <label className="w-full mb-1 font-semibold">Comission</label>
            <input
              type="number"
              placeholder="Comission"
              className="w-full mb-4"
              onChange={(e) => setFairshare({ ...fairshare, comission: +e.target.value })}
              defaultValue={fairshare.comission}
            />
            <div className="flex gap-x-4">
              <Button
                text={isFetching ? 'Loading' : 'Submit'}
                handleClick={() => (isFetching ? {} : handleSubmit())}
              />
              <Button
                text="Cerrar"
                btnProps={{ style: 'outline' }}
                handleClick={() => setModalOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Component;
