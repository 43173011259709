import axios from 'axios';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  AdminHome,
  CreateRefferalCode,
  CreateTransaction,
  CreateUser,
  Dashboard,
  Deposit,
  EditTransaction,
  Home,
  ListAllUsers,
  ListFairshares,
  ListRefferalCodes,
  ListTransactions,
  ListTransactionsRequests,
  Login,
  Profile,
  RecoverAccount,
  Register,
  UploadFairshare,
  Withdraw,
} from './pages';
import { clearStateUserData } from './store/features/auth.slice';

const AppShell = () => {
  const { token, user } = useAppSelector((state) => state.auth);
  const router = createBrowserRouter([
    {
      path: '/',
      element: token !== null ? user?.type === 'admin' ? <Dashboard /> : <Dashboard /> : <Home />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/login',
      element: token === null ? <Login /> : <Navigate to="/" />,
    },
    {
      path: '/recover-account',
      element: <RecoverAccount />,
    },
    {
      path: '/deposit',
      element: token !== null ? <Deposit /> : <Login />,
    },
    {
      path: '/profile',
      element: token !== null ? <Profile /> : <Login />,
    },
    {
      path: '/withdraw',
      element: token !== null ? <Withdraw /> : <Login />,
    },
    {
      path: '/admin',
      element: token !== null && user?.type === 'admin' ? <AdminHome /> : <Login />,
    },
    {
      path: '/admin/users',
      element: token !== null && user?.type === 'admin' ? <ListAllUsers /> : <Login />,
    },
    {
      path: '/admin/users/create',
      element: token !== null && user?.type === 'admin' ? <CreateUser /> : <Login />,
    },
    {
      path: '/admin/refferal-codes',
      element: token !== null && user?.type === 'admin' ? <ListRefferalCodes /> : <Login />,
    },
    {
      path: '/admin/refferal-codes/create',
      element: token !== null && user?.type === 'admin' ? <CreateRefferalCode /> : <Login />,
    },
    {
      path: '/admin/transactions',
      element: token !== null && user?.type === 'admin' ? <ListTransactions /> : <Login />,
    },
    {
      path: '/admin/transactions/create',
      element: token !== null && user?.type === 'admin' ? <CreateTransaction /> : <Login />,
    },
    {
      path: '/admin/transactions/:id/edit',
      element: token !== null && user?.type === 'admin' ? <EditTransaction /> : <Login />,
    },
    {
      path: '/admin/transactions-requests',
      element: token !== null && user?.type === 'admin' ? <ListTransactionsRequests /> : <Login />,
    },
    {
      path: '/admin/fairshares',
      element: token !== null && user?.type === 'admin' ? <ListFairshares /> : <Login />,
    },
    {
      path: '/admin/fairshares/upload',
      element: token !== null && user?.type === 'admin' ? <UploadFairshare /> : <Login />,
    },
  ]);
  const dispatch = useAppDispatch();
  //axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const userToken = localStorage.getItem('Chispi_token');
        if (userToken) {
          config.headers.Authorization = `Bearer ${userToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
        dispatch(clearStateUserData());
        window.dispatchEvent(new Event('storage'));
        window.location.reload();
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );

  /* ---------------------------------- Mount --------------------------------- */
  window.addEventListener('storage', () => {
    if (!localStorage.getItem('Chispi_token')) {
      dispatch(clearStateUserData());
    }
  });
  return <RouterProvider router={router} />;
};
export default AppShell;
