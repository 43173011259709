import React from 'react';
import { AdminDashboard } from '..';
import { FairsharesTable } from '../../components/organisms/Tables';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fairsharesQuery } from '../../store/api/admin.api';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { fairshares } = useAppSelector((state) => state.admin);
  React.useEffect(() => {
    dispatch(fairsharesQuery());
  }, []);

  return (
    <Layout>
      <AdminDashboard
        title="List Fairshares"
        button={{
          text: 'New Fairshare',
          handleClick: () => navigate('/admin/fairshares/upload'),
        }}>
        <FairsharesTable fairshares={fairshares} />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
