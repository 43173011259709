import React, { useMemo, useState } from 'react';
import { FundsInsights, HistoryRequests, HistoryTransactions } from '../../components/molecules';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getFairshareHistoryQuery,
  getFairshareValueQuery,
  getTransactionsQuery,
  getTransactionsRequestsQuery,
  getUsersForComercialQuery,
  getWalletQuery,
} from '../../store/api/user.api';
import { Chart, FairshareChart } from '../../components/organisms';
import { ComercialUsersTable, MonthlyComissionsTable } from '../../components/organisms/Tables';
import { getYearsBetween } from '../../utils/validate';

const Page = () => {
  const dispatch = useAppDispatch();
  const {
    history,
    transactions,
    transactions_requests,
    consolidated_shares,
    frozen_shares,
    fairshare_value,
    fairshare_history,
    users,
    comissions,
  } = useAppSelector((state) => state.user);
  const { user } = useAppSelector((state) => state.auth);
  if (!user) return <></>;

  const [yearBalanceSelected, setBalanceYearSelected] = useState<string>(
    String(new Date().getFullYear())
  );

  const historyFiltered = useMemo(() => {
    return history.filter((item) => item.date.includes(yearBalanceSelected));
  }, [history, yearBalanceSelected]);

  const yearsSinceInit = getYearsBetween(new Date('2022-09-23'));

  React.useEffect(() => {
    dispatch(getWalletQuery());
    dispatch(getFairshareValueQuery());
    dispatch(getTransactionsQuery());
    dispatch(getTransactionsRequestsQuery());
    dispatch(getFairshareHistoryQuery(String(new Date().getFullYear())));
    if (user?.type !== 'user') {
      dispatch(getUsersForComercialQuery());
    }
  }, []);
  return (
    <Layout>
      <div className="container mx-auto w-full z-10 py-40">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-5 md:gap-12">
          <div>
            <div className="bg-violet text-white rounded-md py-5 md:py-10 px-5 md:px-10">
              <p className="text-lg font-semibold uppercase">Consolidated funds</p>
              <p className="font-bold text-h4 md:text-h3">
                <span className="text-lime">USDT </span>
                {(consolidated_shares * fairshare_value).toFixed(2)}
              </p>
              <p className="text-xs  uppercase">
                Pending confirmation
                <span className="font-bold text-xs">
                  <span className="text-lime"> USDT </span>
                  {frozen_shares !== 0 ? frozen_shares.toFixed(2) : frozen_shares}
                </span>
              </p>
            </div>
          </div>
          <div>
            <FundsInsights />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 md:flex md:flex-wrap gap-x-12">
          <div className="lg:w-auto md:w-full">
            <div className="mb-5 border border-darkgray rounded-t-md relative">
              <div className="bg-darkgray rounded-t-md">
                <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg mb-4">
                  Balance history
                </h2>
                <select
                  onChange={(e) => setBalanceYearSelected(e.target.value)}
                  className="absolute top-1 md:top-2 right-4 !bg-black rounded-md !text-xs !md:text-sm !border-b-0">
                  {yearsSinceInit.map((year, i) => (
                    <option
                      key={i}
                      value={year}
                      selected={String(year) === String(new Date().getFullYear())}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <Chart data={historyFiltered} fairshare={fairshare_value} />
            </div>
          </div>
          <div className="mb-5 border border-darkgray rounded-t-md">
            <div className="relative bg-darkgray rounded-t-md">
              <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg  mb-4">
                Fund return
              </h2>
              <select
                onChange={(e) => dispatch(getFairshareHistoryQuery(String(e.target.value)))}
                className="absolute top-1 md:top-2 right-4 !bg-black rounded-md !text-xs !md:text-sm !border-b-0">
                {yearsSinceInit.map((year, i) => (
                  <option
                    key={i}
                    value={year}
                    selected={String(year) === String(new Date().getFullYear())}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <FairshareChart data={fairshare_history} fairshare={fairshare_value} />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 md:flex md:flex-wrap gap-x-12">
          <div className="lg:w-auto md:w-full mb-5">
            <HistoryRequests transactions={transactions_requests} />
          </div>
          <div className="lg:w-auto md:w-full mb-5">
            <HistoryTransactions transactions={transactions} />
          </div>
        </div>
        {user?.type !== 'user' && (
          <div className="lg:grid lg:grid-cols-2 md:flex md:flex-wrap gap-x-12">
            <div className="lg:w-auto md:w-full mb-5">
              <ComercialUsersTable users={users} fairshare_value={fairshare_value} />
            </div>
            <div className="lg:w-auto md:w-full mb-5">
              <MonthlyComissionsTable comissions={comissions} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default Page;
