import { ReferralCode } from '../../../models/ReferralCode';

interface IProps {
  codes: ReferralCode[];
}
const Component = (props: IProps) => {
  const { codes } = props;

  return (
    <>
      <table className="table-auto overflow-scroll w-full responsive text-center border border-gray-400">
        <thead>
          <th className="py-2">User</th>
          <th className="py-2">Code</th>
        </thead>
        <tbody>
          <>
            {codes.map((c) => (
              <tr key={c._id}>
                <td className="py-2">
                  {c.user.firstName} {c.user.lastName}
                </td>
                <td className="py-2">{c.code}</td>
              </tr>
            ))}
          </>
        </tbody>
      </table>
    </>
  );
};
export default Component;
