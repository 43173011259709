import { useInView } from 'react-intersection-observer';
import {
  ContactContent,
  FaqsContent,
  FundsContent,
  OpportunityContent,
  StrategiesContent,
  ToolsContent,
} from '..';
import chevronRight from '../../../assets/icons/chevronRight.svg';
import chevronUp from '../../../assets/icons/chevronUp.svg';

export interface ITitleSection {
  title: string;
  number: string;
  id: string;
  isOpen: boolean;
  setIsOpen: Function;
}

const Component = (props: ITitleSection) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const { title, number, id, isOpen, setIsOpen } = props;

  return (
    <div
      className={`w-full border-t border-b border-color-white cursor-pointer  ease-in-out duration-500 ${
        inView ? 'opacity-100' : 'opacity-0'
      }`}
      ref={ref}>
      <div
        className="container mx-auto flex flex-wrap w-full items-center justify-between h-[140px]"
        onClick={() => setIsOpen()}>
        <div className="flex flex-wrap items-center">
          <span
            className={`text-2xl md:text-h3 font-bold  ${
              isOpen ? 'text-lime' : 'text-transparent font-outline-2'
            } pr-2`}>
            {number}
          </span>
          <h2 className={`text-2xl lg:text-h3 font-bold uppercase ${isOpen && 'text-violet'}`}>
            {title}
          </h2>
        </div>
        <img src={isOpen ? chevronUp : chevronRight} alt="" />
      </div>
      {id === 'opportunity' && <OpportunityContent isOpen={isOpen} />}
      {id === 'funds' && <FundsContent isOpen={isOpen} />}
      {id === 'tools' && <ToolsContent isOpen={isOpen} />}
      {id === 'strategies' && <StrategiesContent isOpen={isOpen} />}
      {id === 'faqs' && <FaqsContent isOpen={isOpen} />}
      {id === 'contact' && <ContactContent isOpen={isOpen} />}
    </div>
  );
};
export default Component;
