interface IProps {
  isOpen: boolean;
}
const Component = (props: IProps) => {
  const { isOpen } = props;
  return (
    <div
      className={`transition-all ease-in-out duration-800 ${
        isOpen ? 'max-h-fit my-20 mb-32' : 'max-h-0 my-0 overflow-hidden'
      } relative w-full container mx-auto flex flex-wrap items-start grid grid-cols-0 lg:grid-cols-2 `}>
      <div className="flex flex-wrap items-start justify-start">
        <div className="w-auto pr-3">
          <p className="w-auto text-h1 font-outline-2 text-transparent">*</p>
        </div>
        <div className="w-3/4">
          <p className="text-md xl:text-lg mb-3">
            While many studies have explored the intricacies of financial market fluctuations, we
            believe that the true definition of market and business cycles lies in the critical
            indicators of leverage and liquidity. Our team leverages these markers to gain a
            competitive edge in the market by analyzing and predicting these key metrics.
          </p>
          <div className="bg-lime w-32 absolute -bottom-10 h-2"></div>
        </div>
      </div>
      <div className="flex flex-wrap items-start justify-start">
        <div className="w-auto pr-3">
          <p className="w-auto text-h1 font-outline-2 text-transparent">*</p>
        </div>
        <div className="w-3/4">
          <p className="text-md xl:text-lg mb-3">
            Looking back at historical market cycles, we have seen that not all tickers or equity
            categories recover from market bursts to reach new all-time highs. However, we are
            optimistic about the future of cryptocurrency as a category and confident that it will
            outperform in the next cycle.
          </p>
          <p className="text-md xl:text-lg">
            As younger generations come of age and begin to invest, we believe the generational bias
            towards this asset class will fully manifest, bringing even greater success to those who
            have positioned themselves accordingly.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Component;
