import { AdminNav } from '../../components/organisms';
import { Layout } from '../../components/templates';

const Page = ({
  children,
  title,
  button,
}: {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  title: string;
  button?: {
    handleClick: () => void;
    text: string;
  };
}) => {
  return (
    <Layout>
      <div className="container mx-auto w-full py-40">
        <AdminNav />
        <div className="w-full flex flex-row items-center justify-start space-x-4">
          <h2 className="text-h3 py-5">{title}</h2>
          {button && (
            <div
              className={`px-4 flex items-center justify-center bg-transparent border border-violet rounded-md text-white py-1 font-semibold  cursor-pointer hover:bg-violet duration-300`}
              onClick={button.handleClick}>
              {button.text}
            </div>
          )}
        </div>

        <>{children}</>
      </div>
    </Layout>
  );
};
export default Page;
