import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export interface walletPayload {
  history: { date: string; fairshare: number; amount: number; since_inception: number }[];
  comissions: { [key: string]: number | string }[];
  consolidated_shares: number;
  frozen_shares: number;
  ytd: number;
  since_inception: number;
}
interface transactionPayload {
  type: 'DEPOSIT' | 'WITHDRAW';
  amount: number;
  chain: string;
  address: string;
  user?: string;
}

export const getWalletQuery = createAsyncThunk('user/wallet', async () => {
  let query;
  try {
    query = await axios.get(`/api/user/wallet`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const getTransactionsRequestsQuery = createAsyncThunk(
  'user/transactions_requests',
  async () => {
    let query;
    try {
      query = await axios.get(`/api/transaction/request`);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const getTransactionsQuery = createAsyncThunk('user/transactions', async () => {
  let query;
  try {
    query = await axios.get(`/api/transaction`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const getFairshareValueQuery = createAsyncThunk('user/fairshare_value', async () => {
  let query;
  try {
    query = await axios.get(`/api/fairshare/last`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const getFairshareHistoryQuery = createAsyncThunk(
  'user/fairshare_history',
  async (year?: string) => {
    let query;
    try {
      query = await axios.get(
        `/api/fairshare/historical?year=${year || String(new Date().getFullYear())}`
      );
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const getUsersForComercialQuery = createAsyncThunk('user/getComercialUsers', async () => {
  let query;
  try {
    query = await axios.get(`/api/user/referrals`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const createTransactionQuery = createAsyncThunk(
  'user/create_transaction',
  async (payload: transactionPayload) => {
    let query;
    try {
      query = await axios.post(`/api/transaction/request`, payload);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
