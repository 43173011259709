import { AdminDashboard } from '..';
import { CreateTransaction, Layout } from '../../components/templates';

const Page = () => {
  return (
    <Layout>
      <AdminDashboard title="Create new Transaction">
        <CreateTransaction />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
