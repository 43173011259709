import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { FairshareHistory } from '../../models/FairshareHistory';
import { Transaction } from '../../models/Transaction';
import { TransactionRequest } from '../../models/TransactionRequest';
import { errorToast, successToast } from '../../utils/toast';
import {
  createTransactionQuery,
  getFairshareHistoryQuery,
  getFairshareValueQuery,
  getTransactionsQuery,
  getTransactionsRequestsQuery,
  getUsersForComercialQuery,
  getWalletQuery,
  walletPayload,
} from '../api/user.api';

interface UserState {
  consolidated_shares: number;
  frozen_shares: number;
  history: { date: string; fairshare: number; amount: number; since_inception: number }[];
  transactions: Transaction[];
  transactions_requests: TransactionRequest[];
  comissions: { [key: string]: number | string }[];
  status: string;
  fairshare_value: number;
  fairshare_history: FairshareHistory[];
  ytd: number;
  since_inception: number;
  users: [];
}

const initialState: UserState = {
  history: [],
  transactions: [],
  transactions_requests: [],
  consolidated_shares: 0,
  frozen_shares: 0,
  fairshare_value: 0,
  fairshare_history: [],
  ytd: 0,
  since_inception: 0,
  users: [],
  comissions: [],
  status: 'idle',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [getWalletQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getWalletQuery.fulfilled.toString()]: (state, action: PayloadAction<walletPayload>) => {
      state.consolidated_shares = action.payload.consolidated_shares;
      state.frozen_shares = action.payload.frozen_shares;
      state.history = action.payload.history;
      state.ytd = action.payload.ytd;
      state.since_inception = action.payload.since_inception;
      state.comissions = action.payload.comissions;
      state.status = 'completed';
    },
    [getWalletQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [getTransactionsQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getTransactionsQuery.fulfilled.toString()]: (state, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload;
      state.status = 'completed';
    },
    [getTransactionsQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [getFairshareValueQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getFairshareValueQuery.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ date: string; value: number }>
    ) => {
      state.fairshare_value = action.payload.value;
      state.status = 'completed';
    },
    [getFairshareValueQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [getFairshareHistoryQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getFairshareHistoryQuery.fulfilled.toString()]: (
      state,
      action: PayloadAction<FairshareHistory[]>
    ) => {
      state.fairshare_history = action.payload;
      state.status = 'completed';
    },
    [getFairshareHistoryQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [getTransactionsRequestsQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getTransactionsRequestsQuery.fulfilled.toString()]: (state, action) => {
      state.transactions_requests = action.payload;
      state.status = 'completed';
    },
    [getTransactionsRequestsQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [createTransactionQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [createTransactionQuery.fulfilled.toString()]: (state) => {
      successToast('Transaction request succesful send');
      state.status = 'completed';
    },
    [createTransactionQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [getUsersForComercialQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [getUsersForComercialQuery.fulfilled.toString()]: (state, action) => {
      state.users = action.payload;
      state.status = 'completed';
    },
    [getUsersForComercialQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
  },
});

export default userSlice.reducer;
