import React from 'react';
import { Layout } from '../../components/templates';
import { useAppDispatch } from '../../hooks';
import { recoverAccountQuery, sendCodeQuery } from '../../store/api/auth.api';
import { useNavigate } from 'react-router-dom';

const RecoverAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState({
    email: '',
    code: '',
    new_password: '',
    new_password_confirm: '',
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(recoverAccountQuery(form)).then(
      (res) => res.meta.requestStatus === 'fulfilled' && navigate('/')
    );
  };
  const sendCode = () => {
    dispatch(sendCodeQuery(form.email));
  };
  React.useEffect(() => {
    form.new_password !== '' &&
    form.new_password_confirm !== '' &&
    form.new_password === form.new_password_confirm &&
    form.email !== '' &&
    form.code !== ''
      ? setIsValid(true)
      : setIsValid(false);
  }, [form]);
  return (
    <Layout>
      <div className="container mx-auto w-full z-10 py-40">
        <h1 className="text-4xl font-bold text-center mb-5">Recover Account</h1>

        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-5 md:gap-12">
          <div className="w-full">
            <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
              <div className="flex flex-wrap items-center justify-between mb-5">
                <label className="text-sm">Email</label>
                <input
                  type={'text'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />

                <h2
                  onClick={sendCode}
                  className="pt-1 font-semibold text-sm text-violet cursor-pointer underline">
                  Send Code
                </h2>
              </div>
              <div className="mb-5">
                <label className="text-sm">Code</label>
                <input
                  type={'text'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, code: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">New Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, new_password: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">Confirm Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, new_password_confirm: e.target.value })}
                />
              </div>

              <div className="w-full flex flex-wrap items-center justify-end">
                <input
                  type="submit"
                  value="Change Password"
                  className="bg-violet"
                  disabled={!isValid}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RecoverAccount;
