import { AdminDashboard } from '..';
import { CreateRefferalCode, Layout } from '../../components/templates';

const Page = () => {
  return (
    <Layout>
      <AdminDashboard title="Create Refferal Code">
        <CreateRefferalCode />
      </AdminDashboard>
    </Layout>
  );
};
export default Page;
