import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Fairshare } from '../../models/Fairshare';
import { ReferralCode } from '../../models/ReferralCode';
import { Transaction } from '../../models/Transaction';
import { TransactionRequest } from '../../models/TransactionRequest';
import { User } from '../../models/User';
import { Wallet } from '../../models/Wallet';
import { errorToast, infoToast, successToast } from '../../utils/toast';
import {
  approveTransactionQuery,
  approveUserQuery,
  createFairshareQuery,
  createRefferalCode,
  createTransactionQuery,
  createUserQuery,
  deleteTransaction,
  deniedTransactionQuery,
  deniedUserQuery,
  editFairshareQuery,
  fairsharesQuery,
  listRefferalCodeQuery,
  transactionsByIdQuery,
  transactionsQuery,
  transactionsRequestQueryAll,
  updateTransactionQuery,
  usersQuery,
  walletsQuery,
} from '../api/admin.api';

interface AdminState {
  users: User[];
  wallets: Wallet[];
  transactions: Transaction[];
  selectedTransaction: Transaction | null;
  transactions_requests: TransactionRequest[];
  fairshares: Fairshare[];
  referralCodes: ReferralCode[];
  status: string;
  isFetching: boolean;
}

const initialState: AdminState = {
  users: [],
  wallets: [],
  transactions: [],
  selectedTransaction: null,
  transactions_requests: [],
  fairshares: [],
  referralCodes: [],
  status: 'idle',
  isFetching: false,
};

export const adminSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [usersQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [usersQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.users = action.payload;
      state.status = 'completed';
    },
    [usersQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [walletsQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [walletsQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.wallets = action.payload;
      state.status = 'completed';
    },
    [walletsQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [transactionsQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [transactionsQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.transactions = action.payload;
      state.status = 'completed';
    },
    [transactionsQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [transactionsByIdQuery.pending.toString()]: (state) => {
      state.isFetching = false;
      state.status = 'pending';
    },
    [transactionsByIdQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.selectedTransaction = action.payload;
      state.status = 'completed';
    },
    [transactionsByIdQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [transactionsRequestQueryAll.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [transactionsRequestQueryAll.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.transactions_requests = action.payload;
      state.status = 'completed';
    },
    [transactionsRequestQueryAll.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [fairsharesQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [fairsharesQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      state.fairshares = action.payload;
      state.status = 'completed';
    },
    [fairsharesQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [createFairshareQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [createFairshareQuery.fulfilled.toString()]: (state) => {
      state.isFetching = false;
      successToast('Fairshare uploaded successfull');
      state.status = 'completed';
    },
    [createFairshareQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [editFairshareQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      infoToast('Revalidando graficos y cuotapartes. Por favor espere...');
      state.status = 'pending';
    },
    [editFairshareQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [editFairshareQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [deleteTransaction.pending.toString()]: (state) => {
      state.isFetching = true;
      infoToast('Revalidando graficos y cuotapartes. Por favor espere...');
      state.status = 'pending';
    },
    [deleteTransaction.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ code: number; message: string }>
    ) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [deleteTransaction.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [approveUserQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [approveUserQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [approveUserQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [deniedUserQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [deniedUserQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [deniedUserQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [approveTransactionQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [approveTransactionQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [approveTransactionQuery.rejected.toString()]: (state, action) => {
      state.isFetching = false;
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [deniedTransactionQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [deniedTransactionQuery.fulfilled.toString()]: (state, action) => {
      state.isFetching = false;
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [deniedTransactionQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
    [createUserQuery.pending.toString()]: (state) => {
      state.isFetching = true;
      state.status = 'pending';
    },
    [createUserQuery.fulfilled.toString()]: (state) => {
      state.isFetching = false;
      successToast('User created succesfull');
      state.status = 'completed';
    },
    [createUserQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
    [createRefferalCode.pending.toString()]: (state) => {
      state.status = 'pending';
      state.isFetching = true;
    },
    [createRefferalCode.fulfilled.toString()]: (state) => {
      state.isFetching = false;
      successToast('Refferal Code created succesfull');
      state.status = 'completed';
    },
    [createRefferalCode.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
    [listRefferalCodeQuery.pending.toString()]: (state) => {
      state.status = 'pending';
      state.isFetching = true;
    },
    [listRefferalCodeQuery.fulfilled.toString()]: (state, action) => {
      state.referralCodes = action.payload;
      state.isFetching = false;
      state.status = 'completed';
    },
    [listRefferalCodeQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
    [createTransactionQuery.pending.toString()]: (state) => {
      state.status = 'pending';
      state.isFetching = true;
    },
    [createTransactionQuery.fulfilled.toString()]: (state) => {
      successToast('Transacción creada correctamente');
      state.isFetching = false;
      state.status = 'completed';
    },
    [createTransactionQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
    [updateTransactionQuery.pending.toString()]: (state) => {
      state.status = 'pending';
      state.isFetching = true;
    },
    [updateTransactionQuery.fulfilled.toString()]: (state) => {
      successToast('Transacción actualizada correctamente');
      state.status = 'completed';
      state.isFetching = false;
    },
    [updateTransactionQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      state.isFetching = false;
      errorToast(action.error.message);
    },
  },
});

export default adminSlice.reducer;
