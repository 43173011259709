import React from 'react';
import { Layout } from '../../components/templates';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createTransactionQuery } from '../../store/api/user.api';
import { useNavigate } from 'react-router-dom';
import { validateAddress } from '../../utils/validate';
import { ChainTypes } from '../../data/chains';
import { usersQuery } from '../../store/api/admin.api';
interface IForm {
  type: 'WITHDRAW';
  amount: number;
  chain: string;
  address: string;
  user?: string;
}
const LoginPage = () => {
  const chains = Object.keys(ChainTypes);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { users } = useAppSelector((state) => state.admin);
  const [isValid, setIsValid] = React.useState<boolean>(false);

  const [form, setForm] = React.useState<IForm>({
    type: 'WITHDRAW',
    amount: 0,
    chain: '',
    address: '',
    user: user?.type === 'user' ? user?._id : '',
  });
  React.useEffect(() => {
    if (user?.type === 'admin') {
      dispatch(usersQuery('ACCEPTED'));
    }
  }, []);
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(createTransactionQuery({ ...form, user: user?._id })).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') navigate('/');
    });
  };
  React.useEffect(() => {
    form.amount !== 0 &&
    form.address !== '' &&
    form.chain !== '' &&
    validateAddress(form.address, form.chain)
      ? setIsValid(true)
      : setIsValid(false);
  }, [form]);
  return (
    <Layout>
      <div className="min-h-screen absolute top-0 left-0 w-full flex flex-wrap justify-center content-center items-center">
        <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
          <h2 className="text-h3 text-center font-semibold uppercase pb-5">
            Create withdraw request
          </h2>
          {user?.type === 'admin' && (
            <div className="mb-5">
              <label className="text-sm">User</label>
              <select
                className="w-full"
                onChange={(e) => setForm({ ...form, user: e.target.value })}>
                <option key={null} value={''}>
                  Select a user
                </option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-5">
            <label className="text-sm">Amount</label>
            <input
              type={'number'}
              onWheel={(event) => event.currentTarget.blur()}
              className="w-full"
              onChange={(e) => setForm({ ...form, amount: +e.target.value })}
            />
          </div>
          <div className="mb-5">
            <label className="text-sm">Chain</label>
            <select
              className="w-full"
              onChange={(e) => setForm({ ...form, chain: e.target.value })}>
              {chains.map((chain) => (
                <option key={chain} value={chain}>
                  {chain}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-5">
            <label className="text-sm">Address</label>
            <input
              type={'text'}
              className="w-full"
              onChange={(e) => setForm({ ...form, address: e.target.value })}
            />
            {form.address !== '' && !validateAddress(form.address, form.chain) && (
              <p className="text-sm text-red-400 mt-2">La address ingresada no es válida</p>
            )}
          </div>

          <div className="w-full flex flex-wrap items-center justify-end">
            <input type="submit" disabled={!isValid} value="Send Request" className="bg-violet" />
          </div>
        </form>
      </div>
    </Layout>
  );
};
export default LoginPage;
